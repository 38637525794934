import { useLocation } from "@remix-run/react"
import { AppLogo } from "./app-logo"

export const ErrorPanel = ({
    errorCode,
    title,
    message,
    showsPathname,
}: {
    errorCode: string,
    title: string,
    message?: string,
    showsPathname: boolean,
}) => {
    const location = useLocation()
    return (
        <div className='px-6 pt-6 pb-10 w-full max-w-2xl mx-auto mt-8 mb-8 flex flex-col items-center justify-center bg-white shadow-md rounded-lg gap-8'>
            <div className='w-full'>
                <AppLogo />
            </div>
            <h1 className='text-7xl font-bold'>{errorCode}</h1>
            <div className='flex flex-col items-center justify-center w-full gap-1'>
                <span className='text-xl font-medium truncate max-w-full'>{title}</span>
                {message && message.length > 0 && (
                    <p className="text-center max-w-full text-slate-600 text-lg mb-6">{message}</p>
                )}
                {showsPathname && (
                    <p className='truncate max-w-full text-slate-600 text-lg'>{location.pathname}</p>
                )}
            </div>
        </div>
    )
}